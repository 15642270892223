import { IInput } from '@/components/form';
import store from '@/store';
import { ITranslations } from '@/translations/index';

interface IFilters {
  search: IInput;
}

export const filters = () => {
  const translations: ITranslations = store.getters.getTranslations;

  const filters: IFilters = {
    search: {
      id: 'filter-search',
      name: 'filter_search',
      value: '',
      placeholder: translations.common.search,
      type: 'text',
    },
  };
  return filters;
};
