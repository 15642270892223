import { MutationPayload } from 'vuex';
import {
  createRocketInstantMessage, getRocketUser,
} from '@/api/chat';
import Offcanvas from '@/components/offcanvas.vue';
import Input from '@/components/form/input.vue';
import Select from '@/components/form/select.vue';
import { IFetchNetworkingParams } from '@/store/modules/networking';
import { INetworking } from '@/models/networking';
import UserCard from '@/partials/UserCard.vue';
import { IUserCard } from '@/partials';
import store from '@/store';
import { filters } from './index';
import { computed, defineComponent, reactive } from '@vue/composition-api';
import useContext from '@/composition/context';
import router from '@/router';

export default defineComponent({
  components: {
    Offcanvas,
    Input,
    Select,
    UserCard,
  },
  setup() {
    const { translations, contextVisitor } = useContext();

    const state = reactive({
      filters: filters(),
    });

    const networking = computed(() => store.getters.getNetworking);
    const networkingState = computed(() => store.getters.getNetworkingState);

    let searchTimeout = 0;

    const nextPage = () => {
      const event = parseInt(router.currentRoute.params.event, 10);

      if (networkingState.value.page) {
        const params: IFetchNetworkingParams = {
          event,
          page: networkingState.value.page + 1,
          page_size: 12,
        };
        store.dispatch('fetchNetworking', params);
      }
    };

    const search = (value: string | number) => {
      const event = parseInt(router.currentRoute.params.event, 10);

      state.filters.search = {
        ...state.filters.search,
        loading: true,
        value,
      };
      clearTimeout(searchTimeout);
      searchTimeout = setTimeout(() => {
        const params: IFetchNetworkingParams = {
          event,
          page: 1,
          page_size: 12,
          search: value as string,
          reset: true,
        };
        store.dispatch('fetchNetworking', params);
        state.filters.search.loading = false;
      }, 1000);
    }

    const getCroppedImage = (url: string) => {
      if (!url || url === '') {
        return undefined;
      }
      const bucketUrl = 'f.beamian.com';
      const indexOfBucket = url.indexOf(bucketUrl);

      const imagePath = url.substr(indexOfBucket + bucketUrl.length + 1, url.length);

      return `https://d2jgquhpz530bi.cloudfront.net/100x100/${imagePath}`;
    }

    const cardConfig = (visitor: INetworking) => {
      const event = parseInt(router.currentRoute.params.event, 10);

      const config: IUserCard = {
        name: visitor.card.title,
        detail: visitor.card.subtitle,
        description: visitor.card.description,
        isCompact: true,
        picture: getCroppedImage(visitor.profile_picture),
        route: `/event/${event}/profile/${visitor.visitor}`,
      };
      return config;
    }

    store.subscribe((mutation: MutationPayload) => {
      if (mutation.type === 'setLanguage') {
        state.filters = filters();
        state.filters.search.onInput = search;
      }
    });

    return {
      contextVisitor,
      translations,
      networking,
      networkingState,
      state,
      nextPage,
      getCroppedImage,
      cardConfig,
    }

  },
  created() {
    const event = parseInt(this.$route.params.event, 10);
    const params: IFetchNetworkingParams = {
      event,
      page: 1,
      page_size: 12,
      reset: true,
    };

    store.dispatch('fetchNetworking', params);
    setTimeout(() => {
      this.$gtag.event('networking_entered', {
        event_category: `event_${this.event}`,
        event_label: `visitor_${this.contextVisitor.id}`,
      });
    }, 3000);
  },
  watch: {
    $route() {
      if (this.$route.query.roomId) {
        const rocketIframe = document.querySelector('iframe');
        if (rocketIframe) {
          const { contentWindow } = rocketIframe;
          if (contentWindow) {
            contentWindow.postMessage({
              externalCommand: 'go',
              path: `direct/${this.$route.query.roomId}?layout=embedded`,
            }, '*');
          }
        }
      }
    },
  },
  methods: {
    openChat(visitor: INetworking) {
      getRocketUser(`u${visitor.user}`).then((response) => {
        const { user } = response.data;
        createRocketInstantMessage(user.username).then((response) => {
          const roomId = response.data.room.rid;
          store.commit('setChatRoom', { rid: roomId, type: 'im', roomName: user.name });
          store.commit('setChatOpen');
          setTimeout(() => {
            this.$gtag.event(`visitor_chat_${visitor.id} `, {
              event_category: `event_${this.event}`,
              event_label: `visitor_${this.contextVisitor.id}`,
            });
          }, 3000);
        });
      })
        .catch((err) => {
          const errors = err.response.data;
          if (errors && errors.length > 0) {
            errors.forEach((error: string) => {
              store.commit('addPopup', {
                message: error,
                type: 'danger',
                autohide: true,
              });
            });
          } else {
            store.commit('addPopup', {
              message: err.message,
              type: 'danger',
              autohide: true,
            });
          }
        });
    }
  }
});